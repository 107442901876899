.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #00000055;
	z-index: 10;
}

.shoppingCart {
	width: 60%;
	height: 90%;
	margin: 2rem auto;
	background-color: #eee;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	position: relative;
}

.shoppingCart .header {
	display: flex;
	background-color: #ccc;
	padding: 10px 20px;
	justify-content: space-between;
}

.close-btn {
	color: var(--main-color);
}

.empty-text {
	display: block;
	padding: 2rem;
	margin: auto;
}

.cart-products {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 1rem 0;
	overflow-y: scroll;
}


/* .cart-product {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: #fff;
	padding: 10px 20px;
	border: 3px solid #eee;
} */

.product-info {
	flex-basis: 50%;
}

.product-info h3 {
	font-weight: 400;
	font-size: 1rem;
}

.cart-product select {
	width: 70px;
	font-size: 1rem;
	outline: none;
}

.cart-products img {
	width: 100px;
	background-color: #fff;
}

.checkout-btn {
	background-color: #2A564C;
	padding: 10px 20px;
	color: #FDFDFD;
	align-self: center;
	margin: 2rem 0;
}
.product-count {
	margin-top: -5px;
	width: 20px;
	height: 20px;
	border-radius: 12px;
	background-color: var(--secondary-color);
	overflow: hidden;
}


.shopping-cart-btn {
	display: flex;
}

