.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #00000055;
	z-index: 10;
}

.donate {
	/* width: 45%; */
	height: 100%;
	margin: 3rem auto;
	background-color: #F6F8F8;
	padding: 1rem;
	position: relative;
	flex-direction: column;
	overflow-y: scroll;
}