@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* .slide-img{
  width: 50%;
  margin: 10rem auto;
  height: 570px;
} */

.slide img{
    /* width: 20rem; */
    margin: 0 auto;
}

.slide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: .4;
}
.activeSlide{
    transform: scale(1.2);
    opacity: 1;
    padding-bottom: 60px;
   
}
.arrow{
    background-color: #1A1C21;
    position: absolute;
    color: white;
    cursor: pointer;
    border: 1px solid white;
    z-index: 10;
    padding: 19px;
    border-radius: 5px;
}
.arrow svg{
    transition: color 300ms;
}

.arrow svg:hover {
    color: #68edff
}

.next {
    right: 27%;
    top: 40%;
}
.prev {
    left: 27%;
    top: 40%;
}
@media screen and (max-width: 640px) {
    .arrow{
    background-color: #1A1C21;
    position: absolute;
    color: white;
    cursor: pointer;
    border: 1px solid white;
    z-index: 10;
    padding: 10px;
    border-radius: 5px;
}
    .next {
        right: -4%;
        top: 40%;
    }
    .prev {
        left: -4%;
        top: 40%;
    }
}
/* mobile slide */
.mobileslide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: .4;
}
/* .mobilenext {
    right: 27%;
    top: 40%;
}
.mobileprev {
    left: 8%;
    top: 40%;
} */